import ChevronLeftIcon from "@heroicons/react/16/solid/ChevronLeftIcon";
import ChevronRightIcon from "@heroicons/react/16/solid/ChevronRightIcon";
import ChevronUpIcon from "@heroicons/react/16/solid/ChevronUpIcon";
import Check from "@mui/icons-material/Check";
import ButtonBase from "@mui/material/ButtonBase";
import Checkbox from "@mui/material/Checkbox";
import Slider from "@mui/material/Slider";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../../assets/colors";

export const Wrapper = styling("div")((props) => ({
  position: "absolute",
  top: "calc(100% + 0.5rem)",
  left: props.$buttonLeft || "auto",
  zIndex: 1000,

  backgroundColor: Color.white,
  border: `3px solid ${Color.black}`,
  borderRadius: "20px",
  boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.16)",

  display: props.$open ? "flex" : "none",
  flexDirection: "column",
  gap: "1rem",

  maxHeight: "725px",
  width: "340px",

  overflow: "hidden",
  scrollBehavior: "smooth",
  cursor: "default",

  "@media (max-height: 900px)": {
    maxHeight: "calc(100vh - 12rem)",
  },

  "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
    backgroundColor: Color.grey,
    overflowY: "auto",
    zIndex: 10,
    width: 4,
  },
  "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
    backgroundColor: Color.hiredlyPurple,
  },
}));

export const InnerWrapper = styling("div")({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  height: "100%",
  padding: "1rem",
  overflow: "auto",
  scrollBehavior: "smooth",

  "&::-webkit-scrollbar": {
    backgroundColor: Color.grey,
    overflowY: "auto",
    zIndex: 10,
    width: 4,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: Color.hiredlyPurple,
  },
});

export const MenuWrapper = styling("div")({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
});

export const MenuContainer = styling("div")((props) => ({
  backgroundColor: Color.white,
  color: Color.textHighEmphasis,
  border: `2px solid ${Color.secondaryGrey}`,
  borderRadius: "8px",

  display: "flex",
  flexDirection: "column",

  maxHeight: "0",
  height: "100%",
  opacity: "0",
  overflow: "hidden",
  margin: "-0.625rem 0",

  transition: "all 0.125s ease-in-out",

  ...(props.$open && {
    maxHeight: "342px",
    opacity: "1",
    margin: "-0.625rem 0 0",
  }),

  ...(props.$selected && {
    border: `2px solid ${Color.hiredlyPurple}`,
  }),
}));

export const TopContainer = styling("div")({
  display: "flex",
  justifyContent: "space-between",

  width: "100%",
  padding: "1rem 1.5rem 0.625rem 0.625rem",

  "& > button:only-child": {
    marginLeft: "auto",
  },
});

export const BottomContainer = styling("div")({
  display: "flex",
  gap: "0.5rem",

  padding: "1.5rem 1rem 1rem",
});

export const CheckboxContainer = styling("div")({
  position: "relative",
});

export const CheckedBox = styling("div")({
  color: Color.white,
  backgroundColor: Color.black,
  border: "1px solid" + Color.black,
  borderRadius: "0.25rem",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  "&:hover": {
    backgroundColor: Color.white,
  },

  height: "18px",
  width: "18px",
});

export const UncheckedBox = styling("div")({
  color: Color.black,
  backgroundColor: Color.white,
  border: "1px solid" + Color.black,
  borderRadius: "0.25rem",

  height: "18px",
  width: "18px",
});

export const MenuButtonDetail = styling("div")({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",

  fontFamily: "Inter",
  fontSize: "0.875rem",
  lineHeight: "20px",
  letterSpacing: "0.112px",
});

export const MenuItemList = styling("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  alignSelf: "stretch",
  flexShrink: 0,

  maxHeight: "210px",
  overflowY: "auto",
});

export const MenuItemDetail = styling("div")({
  display: "flex",
  alignItems: "center",
  gap: "1rem",

  fontFamily: "Inter",
  fontSize: "0.875rem",
  fontWeight: "400",
  lineHeight: "24px",
  letterSpacing: "0.15px",
  textAlign: "left",
});

export const MenuCountContainer = styling("div")({
  display: "flex",
  alignItems: "center",
  gap: "0.375rem",
});

export const FilterSalaryContainer = styling("div")({
  fontFamily: "Inter",
  fontSize: "0.875rem",
  fontWeight: "700",
  lineHeight: "24px",
  letterSpacing: "0.15px",
  textTransform: "capitalize",
  textAlign: "center",

  padding: "2rem 1rem 0rem",
});

export const SliderContainer = styling("div")({
  width: "100%",
  maxWidth: "18.75rem",
  margin: "auto",
  padding: "1.25rem 0",
});

export const GlobalHireContainer = styling("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  width: "100%",
});

export const FilterCount = styling("span")((props) => ({
  backgroundColor: Color.lightHiredlyPurple,
  color: Color.textMediumEmphasis,
  border: `1px solid ${Color.darkPurple}`,
  borderRadius: "0.25rem",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  fontFamily: "Inter",
  fontSize: "0.875rem",
  lineHeight: "24px",
  letterSpacing: "0.15px",

  height: "24px",
  width: "24px",

  ...(props.$salary && {
    width: "fit-content",
    padding: "0 0.25rem",
  }),
}));

export const FilterSpecialisationCount = styling("span")({
  backgroundColor: Color.black,
  color: Color.white,
  borderRadius: "6.25rem",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  fontFamily: "Inter",
  fontSize: "0.875rem",
  lineHeight: "24px",
  letterSpacing: "0.15px",

  height: "24px",
  width: "24px",
});

export const GlobalHireSubtitle = styling("span")({
  color: Color.textMediumEmphasis,

  fontSize: "0.625rem",
  fontStyle: "italic",
  lineHeight: "20px",
});

export const MenuButton = styling(ButtonBase)((props) => ({
  backgroundColor: Color.white,
  color: Color.textHighEmphasis,
  border: `2px solid ${Color.secondaryGrey}`,
  borderRadius: "8px",

  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  alignSelf: "stretch",
  flexShrink: 0,

  height: "40px",
  width: "100%",
  padding: "0.5rem 0.875rem 0.5rem 1.25rem",

  transition: "all 0.15s ease-in-out",

  ...(props.$selected && {
    border: `2px solid ${Color.hiredlyPurple}`,
  }),

  ...(props.$globalHire && {
    color: Color.textDisabled,
    gap: "0.875rem",
    height: "56px",

    ...(props.$selected && {
      color: Color.textHighEmphasis,
    }),

    "&:hover": {
      color: Color.textHighEmphasis,
    },
  }),
}));

export const MenuItem = styling(ButtonBase)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  width: "100%",
  padding: "0.5rem 0.75rem 0.5rem 0.875rem",
});

export const SelectButton = styling(ButtonBase)({
  color: Color.hiredlyPurple,

  fontFamily: "Inter",
  fontSize: "1rem",
  fontWeight: "700",
  lineHeight: "normal",
});

export const ButtonStyled = styling(ButtonBase)((props) => ({
  backgroundColor: Color.black,
  color: Color.white,
  border: `2px solid ${Color.black}`,
  borderRadius: "6.25rem",

  fontFamily: "Inter",
  fontSize: "1rem",
  fontWeight: "700",
  lineHeight: "normal",

  height: "42px",
  width: "100%",
  padding: "0 2.5rem",

  ...(props.$outlined && {
    backgroundColor: Color.white,
    color: Color.black,
  }),
}));

export const CheckboxButton = styling(Checkbox)({
  padding: "0",

  "& .Mui-checked": {
    color: Color.black,
  },

  "&:hover": {
    background: "transparent",
  },
});

export const SliderStyled = styling(Slider)({
  marginTop: "0.75rem",
  margin: "auto",

  "& .MuiSlider-rail": {
    backgroundColor: Color.black,
  },

  "& .MuiSlider-thumb": {
    backgroundColor: Color.black,
  },
});

export const ArrowUpIcon = styling(ChevronUpIcon)((props) => ({
  height: "20px",
  width: "20px",

  transition: "all 0.125s ease-in-out",

  ...(props.$open && {
    transform: "rotate(180deg)",
  }),
}));

export const ArrowLeftIcon = styling(ChevronLeftIcon)({
  height: "20px",
  width: "20px",
});

export const ArrowRightIcon = styling(ChevronRightIcon)({
  height: "20px",
  width: "20px",
});

export const CheckboxIcon = styling(Check)({
  width: "12px",
  height: "12px",
});
