import useMediaQuery from "@mui/material/useMediaQuery";
import { useIsClient } from "@uidotdev/usehooks";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { Fragment, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { convertIdsToUrl } from "../../../helpers/filter_management";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import useJobApplication from "../../../hooks/useJobApplication";
import {
  updateBulkApplyState,
  updatePostApplyDialog,
  updateRefreshDialogStatus,
  updateShowSimilarJobsStatus,
} from "../../../redux/actions/dialog_action";
import {
  clearJobList,
  updateSelectedJob,
} from "../../../redux/actions/job_action";
import { getUser } from "../../../redux/actions/user_action";
import { BoxContainer, ButtonText } from "../../for-you/styles";
import SharedNavbar2 from "../../shared/SharedNavbar/Navbar/Navbar";
import SharedToast from "../../shared/SharedToast/SharedToast";
import AccordionForYou from "../AccordionForYou/AccordionForYou";
import AccordionJobList from "../AccordionJobList/AccordionJobList";
import JobListNavigation from "../JobListNavigation/JobListNavigation";
import JobSearchOptions from "../JobSearchOptions/JobSearchOptions";
import { Wrapper } from "./styles";

const CreateResumePopup = dynamic(
  () =>
    import(
      "../../profile/ResumePopups/ApplyJobResumeStatus/CreateResume/CreateResume"
    ),
  { ssr: false }
);
const RejectedResumePopup = dynamic(
  () =>
    import(
      "../../profile/ResumePopups/ApplyJobResumeStatus/RejectedResume/RejectedResume"
    ),
  { ssr: false }
);
const SharedNationalityDialog = dynamic(
  () => import("../../shared/SharedNationalityDialog/SharedNationalityDialog"),
  { ssr: false }
);
const JobAlertDialog = dynamic(
  () => import("../JobAlertDialog/JobAlertDialog"),
  { ssr: false }
);
const JobCountryPreferenceDialog = dynamic(
  () => import("../JobCountryPreferenceDialog/JobCountryPreferenceDialog"),
  { ssr: false }
);
const PostApplyDialog = dynamic(
  () => import("../PostApplyDialog/PostApplyDialog"),
  { ssr: false }
);
const RefreshDialog = dynamic(
  () => import("../../for-you/RefreshDialog/RefreshDialog"),
  { ssr: false }
);
const JobAlertsCreateDialog = dynamic(
  () => import("../../profile/JobAlertsCreateDialog/JobAlertsCreateDialog"),
  { ssr: false }
);
const JobAlertsNotificationDialog = dynamic(
  () =>
    import(
      "../../profile/JobAlertsNotificationDialog/JobAlertsNotificationDialog"
    ),
  { ssr: false }
);
const VisitorJobAlertDialog = dynamic(
  () => import("../VisitorJobAlertDialog/VisitorJobAlertDialog"),
  { ssr: false }
);
const VisitorJobAlertSuccesDialog = dynamic(
  () =>
    import(
      "../VisitorJobAlertDialog/VisitorJobAlertSuccessDialog/VisitorJobAlertSuccessDialog"
    ),
  { ssr: false }
);

export default function JobListWrapper(props) {
  const { serverJobs, ssrFilterParams, ssrPageParams, currentTab } = props;

  const dispatch = useDispatch();

  const isClient = useIsClient();
  const isDesktop = useMediaQuery("(min-width: 1280px)");

  const [selectedTab, setSelectedTab] = useState(currentTab);
  const [selectedForYouJobs, setSelectedForYouJobs] = useState([]);
  const [selectedRecommendedJobs, setSelectedRecommendedJobs] = useState([]);
  const [currentForYouPage, setCurrentForYouPage] = useState(0);
  const [currentJobListPage, setCurrentJobListPage] = useState(0);
  const [refreshLoadingFYP, setRefreshLoadingFYP] = useState(false);
  const [refreshLoadingRecommended, setRefreshLoadingRecommended] =
    useState(false);
  const [viewedFYPJobsCards, setViewedFYPJobsCards] = useState(new Set());
  const [viewedRecommendedJobsCards, setViewedRecommendedJobsCards] = useState(
    new Set()
  );
  const [dialogOpen, setDialogOpen] = useState(true);

  const jobSelectedState =
    selectedRecommendedJobs.length > 0 || selectedForYouJobs.length > 0;

  const alertBanner = useSelector((state) => state.dialog.showAlertBanner);
  const specialisationList = useSelector((state) => state.jobs?.specialisation);
  const stateList = useSelector((state) => state.jobs?.stateRegions);
  const refreshDialog = useSelector(
    (state) => state?.dialog?.showRefreshDialog
  );
  const forYouJobs = useSelector((state) => state.jobs?.forYouJobs);
  const recommendedJobs = useSelector((state) => state?.jobs?.recommendedJobs);
  const { status: showPostApplyDialog } = useSelector(
    (state) => state.dialog.showPostApplyDialog
  );
  const toastQueue = useRef([]);
  const jobListRef = useRef(null);

  const trackCurrentPage = (jobTypes, currentPage, jobsPerPage) => {
    const startIndex = currentPage * jobsPerPage;
    return jobTypes.slice(startIndex, startIndex + jobsPerPage);
  };

  const filteredFYPJobs = trackCurrentPage(forYouJobs, currentForYouPage, 5);
  const filteredRecommendedJobs = trackCurrentPage(
    recommendedJobs,
    currentJobListPage,
    6
  );

  const jobOrigin = "job_list";
  const jobDiscovery = "search";

  const user = getUser();
  const router = useRouter();

  const {
    applyJobFunc,
    checkLoggedIn,
    checkNationalityPhoneNumberLocation,
    checkRightToWorkAndRelocate,
    checkResumeRejected,
    checkScrapedJob,
  } = useJobApplication(jobOrigin, jobDiscovery);

  function triggerToast(toastMessage, state) {
    toast(() => toastMessage, {
      className: state ? "Toast-success" : "Toast-error",
    });
  }

  function handleTabNavClick(tab) {
    if (tab === selectedTab) {
      return;
    }

    setSelectedTab(tab);
    dispatch(clearJobList());
    if (tab === "job-list" && Object.keys(ssrFilterParams).length > 0) {
      const { path, query } = convertIdsToUrl(
        ssrFilterParams,
        specialisationList,
        stateList
      );
      router.push({ pathname: path, query }, undefined, { shallow: false });
    }
  }

  async function onApplyJobClick(e, job) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    dispatch(updateSelectedJob(job));
    // NOT LOGGED IN
    if (checkLoggedIn(job)) {
      sendTrackingEvent({
        event: "applied-job",
        "job-title": job.title,
        resume: false,
        logged_in: false,
        scraped: job.scraped !== "",
        "job-bucket-id": null,
        "job-id": job?.id,
      });
      return;
    }
    // CONDITION - NO NATIONALITY OR MOBILE NUMBER OR CURRENT LOCATION
    if (checkNationalityPhoneNumberLocation(job)) {
      return;
    }

    // CONDITION - RIGHT TO WORK OR RELOCATION PREFERENCE
    if (checkRightToWorkAndRelocate(job)) {
      return;
    }

    // CONDITION - NO RESUME / RESUME REJECTED
    // Do not send in application if user has no resume/rejected
    if (checkResumeRejected(job)) {
      sendTrackingEvent({
        event: "applied-job",
        "job-title": job.title,
        resume: false,
        logged_in: true,
        scraped: job.scraped !== "",
        "job-bucket-id": null,
        "job-id": job?.id,
      });
      return;
    }

    // CONDITION - SCRAPED JOB
    if (checkScrapedJob(job)) {
      sendTrackingEvent({
        event: "CE_job-apply",
        origin: "job_list",
        "job-discovery": jobDiscovery,
        "job-title": job.title,
        "job-id": job?.id,
        resume: false,
        logged_in: true,
        scraped: job.scraped !== "",
        job_preference_toggle: "off",
        "job-bucket-id": null,
      });
      return;
    }

    const params = {
      jobId: job.id,
      canCancel: false,
      origin: jobOrigin,
      jobDiscovery: jobDiscovery,
    };

    setDialogOpen(true);
    setViewedRecommendedJobsCards(new Set());
    const response = await applyJobFunc(job, params);

    if (response.success) {
      sendTrackingEvent({
        event: "CE_job-apply",
        origin: "job_list",
        "job-discovery": jobDiscovery,
        "job-title": job.title,
        "job-id": job?.id,
        resume: true,
        logged_in: true,
        scraped: job.scraped !== "",
        job_preference_toggle: "off",
        "job-bucket-id": response?.payload?.jobBucketId ?? null,
      });
    }
    dispatch(updateSelectedJob({}));
  }

  async function onSimilarJobsClick(e, job) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    const params = {
      globalId: job?.globalId,
      first: 6,
    };

    setDialogOpen(true);
    dispatch(updateShowSimilarJobsStatus(true));
    dispatch(updateBulkApplyState("post-apply"));

    sendTrackingEvent({
      event: "CE_view_similar_jobs_click",
      origin: "job_listing_page",
      job_discovery: "view_similar_jobs",
      is_visitor: user === null ? "true" : "false",
    });

    dispatch(
      updatePostApplyDialog({
        status: true,
        scraped: job?.category === "scraped",
        jobGlobalId: job?.globalId,
      })
    );
    setViewedRecommendedJobsCards(new Set());
  }

  function actionButton({ closeToast, data }) {
    function handleTriggerAction() {
      data.action();
      closeToast(true);
    }

    return (
      <BoxContainer justifyContent={"space-between"} alignItems={"center"}>
        <span>Jobs refreshed</span>
        <ButtonText onClick={handleTriggerAction}>Undo</ButtonText>
      </BoxContainer>
    );
  }

  function handleUndoRefresh() {
    // create a generate function to perform undo action based on its params
    const handleUndo = (currentPage, setPage, setLoading, setSelectedJobs) => {
      if (currentPage < 0) {
        return;
      }

      setPage(currentPage);
      setLoading(true);
      setSelectedJobs([]);

      if (toastQueue.current.length >= 2) {
        const oldestToast = toastQueue.current.shift();
        if (oldestToast) toast.dismiss(oldestToast);
      }

      const newToast = toast.success("Jobs reverted.", { timer: 5000 });
      toastQueue.current.push(newToast);

      setTimeout(() => {
        setLoading(false);
      }, 300);
    };

    if (selectedTab === "for-you") {
      if (showPostApplyDialog) {
        handleUndo(
          currentJobListPage,
          setCurrentJobListPage,
          setRefreshLoadingRecommended,
          setSelectedRecommendedJobs
        );
      } else {
        handleUndo(
          currentForYouPage,
          setCurrentForYouPage,
          setRefreshLoadingFYP,
          setSelectedForYouJobs
        );
      }
    } else {
      handleUndo(
        currentJobListPage,
        setCurrentJobListPage,
        setRefreshLoadingRecommended,
        setSelectedRecommendedJobs
      );
    }
  }

  function handleRefreshJobs(fromDialog = false) {
    if (shouldShowRefreshDialog(fromDialog)) {
      dispatch(updateRefreshDialogStatus(true));
      return;
    }

    if (selectedTab === "for-you") {
      handleForYouTabRefresh(fromDialog);
    } else if (selectedTab === "job-list") {
      handleJobListTabRefresh(fromDialog);
    }
  }

  function shouldShowRefreshDialog(fromDialog) {
    return !refreshDialog && dialogOpen;
  }

  function handleForYouTabRefresh(fromDialog) {
    if (showPostApplyDialog) {
      refreshRecommendedJobs();
    } else {
      refreshForYouJobs();
    }
  }

  function refreshRecommendedJobs() {
    if (recommendedJobs?.length > 0) {
      const nextPage =
        (currentJobListPage + 1) % Math.ceil(recommendedJobs.length / 6);
      if (nextPage === 0) {
        router.reload();
        return;
      }
      resetRecommendedJobsScroll();
      updateJobListPage(
        nextPage,
        setCurrentJobListPage,
        setRefreshLoadingRecommended,
        setSelectedRecommendedJobs
      );
    }
  }

  function refreshForYouJobs() {
    if (forYouJobs?.length > 0) {
      const nextPage =
        (currentForYouPage + 1) % Math.ceil(forYouJobs.length / 5);
      if (nextPage === 0) {
        router.reload();
        return;
      }
      resetForYouJobsScroll();
      updateJobListPage(
        nextPage,
        setCurrentForYouPage,
        setRefreshLoadingFYP,
        setSelectedForYouJobs
      );
    }
  }

  function handleJobListTabRefresh() {
    if (recommendedJobs?.length > 0) {
      const nextPage =
        (currentJobListPage + 1) % Math.ceil(recommendedJobs.length / 6);
      if (nextPage === 0) {
        router.reload();
        return;
      }
      resetRecommendedJobsScroll();
      updateJobListPage(
        nextPage,
        setCurrentJobListPage,
        setRefreshLoadingRecommended,
        setSelectedRecommendedJobs
      );
    }
  }

  function updateJobListPage(nextPage, setPage, setLoading, setSelectedJobs) {
    setPage(nextPage);
    setLoading(true);
    setSelectedJobs([]);

    if (toastQueue.current.length >= 2) {
      const oldestToast = toastQueue.current.shift();
      if (oldestToast) toast.dismiss(oldestToast);
    }

    const newToast = toast.success(actionButton, {
      autoClose: 8000,
      data: {
        action: handleUndoRefresh,
      },
    });

    toastQueue.current.push(newToast);

    setTimeout(() => {
      setLoading(false);
    }, 300);
  }

  function resetRecommendedJobsScroll() {
    if (jobListRef.current) {
      jobListRef.current.style.scrollBehavior = "auto";
      jobListRef.current.scrollLeft = 0;
      setTimeout(() => {
        if (jobListRef.current) {
          jobListRef.current.style.scrollBehavior = "smooth";
        }
      }, 0);
    }
  }

  function resetForYouJobsScroll() {
    if (jobListRef.current) {
      jobListRef.current.style.scrollBehavior = "auto";
      jobListRef.current.scrollLeft = 0;
      setTimeout(() => {
        if (jobListRef.current) {
          jobListRef.current.style.scrollBehavior = "smooth";
        }
      }, 0);
    }
  }

  return (
    <Fragment>
      <SharedNavbar2 page={"job-list-page"} />
      <Wrapper selectedTab={selectedTab}>
        <JobListNavigation
          selectedTab={selectedTab}
          handleTabNavClick={handleTabNavClick}
        />
        {/* create dedicate space for ssr loading for prevent layout shift */}
        {!isClient ? <div style={{ marginTop: "80px" }}></div> : null}
        {!isDesktop && selectedTab === "job-list" ? <JobSearchOptions /> : null}
        {selectedTab === "for-you" ? (
          <AccordionForYou
            jobListRef={jobListRef}
            jobs={forYouJobs}
            jobListTab={selectedTab}
            handleTabChange={handleTabNavClick}
            handleRefreshJobs={handleRefreshJobs}
            handleUndoRefresh={handleUndoRefresh}
            selectedJobs={selectedForYouJobs}
            setSelectedJobs={setSelectedForYouJobs}
            setViewedRecommendedJobsCards={setViewedRecommendedJobsCards}
            filteredJobs={filteredFYPJobs}
            setViewedCards={setViewedFYPJobsCards}
            currentPage={currentForYouPage}
            setCurrentPage={setCurrentForYouPage}
            refreshLoading={refreshLoadingFYP}
            setDialogOpen={setDialogOpen}
          />
        ) : null}
        {selectedTab === "job-list" ? (
          <AccordionJobList
            serverJobs={serverJobs}
            ssrFilterParams={ssrFilterParams}
            ssrPageParams={ssrPageParams}
            onApplyJobClick={onApplyJobClick}
            onSimilarJobsClick={onSimilarJobsClick}
          />
        ) : null}
      </Wrapper>
      {/* Popup components */}
      {isClient ? (
        <>
          <SharedToast />
          <CreateResumePopup />
          <RejectedResumePopup />
          <PostApplyDialog
            jobListRef={jobListRef}
            page={currentTab}
            handleTabChange={handleTabNavClick}
            handleRefreshJobs={handleRefreshJobs}
            selectedJobs={selectedRecommendedJobs}
            filteredJobs={filteredRecommendedJobs}
            setSelectedJobs={setSelectedRecommendedJobs}
            refreshLoading={refreshLoadingRecommended}
            setViewedCards={setViewedRecommendedJobsCards}
            currentPage={currentJobListPage}
            setDialogOpen={setDialogOpen}
            setCurrentPage={setCurrentJobListPage}
          />
          <RefreshDialog
            isDesktop={isDesktop}
            jobs={
              showPostApplyDialog ? filteredRecommendedJobs : filteredFYPJobs
            }
            viewedCards={
              showPostApplyDialog
                ? viewedRecommendedJobsCards
                : viewedFYPJobsCards
            }
            handleRefreshJobs={handleRefreshJobs}
            jobSelectedState={jobSelectedState}
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
          />
          <JobAlertDialog />
          <SharedNationalityDialog jobOrigin={"job_list"} />
          <JobCountryPreferenceDialog
            onApplyJobClicked={onApplyJobClick}
            useLocalApply={true}
            origin={"job_list"}
          />
          <JobAlertsCreateDialog
            selectedCountry={"my"}
            triggerToast={triggerToast}
          />
          <JobAlertsNotificationDialog selectedCountry={"my"} />
          <VisitorJobAlertDialog />
          <VisitorJobAlertSuccesDialog />
        </>
      ) : null}
    </Fragment>
  );
}
