/**
 * JobSearchOptionsMenu Component
 * A dropdown menu component that provides job search filtering options including:
 * - Specialisation selection with nested subspecialisations
 * - State/region filtering
 * - Job type filtering
 * - Experience level filtering
 * - Salary range selection
 */
import { useClickAway } from "@uidotdev/usehooks";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { jobTypes } from "../../../../helpers/constant";
import { calibrateSalary } from "../../../../helpers/data_management";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  BottomContainer,
  ButtonStyled,
  CheckboxButton,
  CheckboxIcon,
  CheckedBox,
  FilterCount,
  FilterSalaryContainer,
  FilterSpecialisationCount,
  GlobalHireContainer,
  GlobalHireSubtitle,
  InnerWrapper,
  MenuButton,
  MenuButtonDetail,
  MenuContainer,
  MenuCountContainer,
  MenuItem,
  MenuItemDetail,
  MenuItemList,
  MenuWrapper,
  SelectButton,
  SliderContainer,
  SliderStyled,
  TopContainer,
  UncheckedBox,
  Wrapper,
} from "./styles";

// Constants for Filter Keys - Used to identify different filter types
const FILTER_KEYS = {
  SPECIALISATION: "specialisation",
  STATE: "state",
  JOB_TYPES: "jobType",
  EXPERIENCE: "experience",
  SALARY: "salary",
};

// Maps filter keys to their corresponding display properties in the data
const ITEM_DISPLAY_KEYS = {
  [FILTER_KEYS.STATE]: "state",
  [FILTER_KEYS.JOB_TYPES]: "jobType",
  [FILTER_KEYS.EXPERIENCE]: "title",
};

// Main Component
export default function JobSearchOptionsMenu(props) {
  const { filterOpen, setFilterOpen, anchorRef, onApply } = props;

  const menuRef = useRef(null);

  const specialisationList = useSelector((state) => state.jobs?.specialisation);
  const stateList = useSelector((state) => state.jobs?.stateRegions);
  const experienceLevelList = useSelector(
    (state) => state.jobs?.experienceLevels
  );
  const jobListFilter = useSelector((state) => state.jobs?.jobListFilter);

  const [buttonLeft, setButtonLeft] = useState(0);
  const [openDropdown, setOpenDropdown] = useState("");
  const [selectedFilter, setSelectedFilter] = useState({});

  const FilterList = useMemo(
    () => [
      {
        title: "Specialisation",
        filter: FILTER_KEYS.SPECIALISATION,
        list: specialisationList,
      },
      {
        title: "State",
        filter: FILTER_KEYS.STATE,
        list: stateList,
      },
      {
        title: "Job Types",
        filter: FILTER_KEYS.JOB_TYPES,
        list: jobTypes,
      },
      {
        title: "Experience",
        filter: FILTER_KEYS.EXPERIENCE,
        list: experienceLevelList,
      },
      {
        title: "Salary",
        filter: FILTER_KEYS.SALARY,
      },
    ],
    [specialisationList, stateList, experienceLevelList]
  );

  // Memoized helper functions
  const isItemSelected = useCallback(
    (type, currentItem) => {
      if (type === FILTER_KEYS.STATE) {
        return selectedFilter?.[type]?.includes(currentItem?.state) || false;
      }
      return (
        selectedFilter?.[type]?.includes(parseInt(currentItem?.id)) || false
      );
    },
    [selectedFilter]
  );

  function handleOpenDropdown(title) {
    setOpenDropdown(openDropdown === title ? "" : title);
  }

  function handleBulkSelect(type, list) {
    setSelectedFilter((prev) => ({
      ...prev,
      [type]:
        prev[type]?.length > 0
          ? []
          : list.map((item) =>
              type === FILTER_KEYS.STATE ? item.state : parseInt(item.id)
            ),
    }));
  }

  function handleClickCheckbox(type, item) {
    setSelectedFilter((prev) => {
      const existingIds = prev[type] || [];
      const itemValue =
        type === FILTER_KEYS.STATE ? item.state : parseInt(item.id);
      const itemExists = existingIds.includes(itemValue);
      return {
        ...prev,
        [type]: itemExists
          ? existingIds.filter((id) => id !== itemValue)
          : [...existingIds, itemValue],
      };
    });
  }

  function handleClearFilter() {
    setSelectedFilter(jobListFilter);
    setFilterOpen(false);
  }

  // Close the menu when clicking away
  useClickAway(menuRef, () => {
    if (filterOpen && anchorRef?.current) {
      anchorRef.current.click();
    }
  });

  // Position the menu relative to the filter button
  useEffect(() => {
    const calculateButtonPosition = () => {
      if (anchorRef?.current) {
        const rect = anchorRef.current.getBoundingClientRect();
        const parentRect =
          anchorRef.current.offsetParent.getBoundingClientRect();
        setButtonLeft(rect.left - parentRect.left);
      }
    };

    // Calculate initial position
    calculateButtonPosition();

    // Add resize listener
    window.addEventListener("resize", calculateButtonPosition);

    // Cleanup
    return () => {
      window.removeEventListener("resize", calculateButtonPosition);
    };
  }, [anchorRef]);

  // Reset dropdown when filter active changes
  useEffect(() => {
    setOpenDropdown("");
  }, [filterOpen]);

  useEffect(() => {
    setSelectedFilter(jobListFilter);
  }, [jobListFilter]);

  // Memoized render functions
  const renderFilterContent = useCallback(
    (filter) => {
      const commonProps = {
        selectedFilter,
        setSelectedFilter,
        onApply,
        handleClearFilter,
      };

      if (filter.title === "Salary") {
        return <FilterSalaryItem {...commonProps} />;
      }

      if (filter.title === "Specialisation") {
        return (
          <FilterSpecialisationItem
            {...commonProps}
            specList={filter.list}
            handleClickCheckbox={handleClickCheckbox}
            isItemSelected={isItemSelected}
          />
        );
      }

      return (
        <FilterDefaultItem
          {...commonProps}
          list={filter.list}
          filterKey={filter.filter}
          handleBulkSelect={handleBulkSelect}
          handleClickCheckbox={handleClickCheckbox}
          isItemSelected={isItemSelected}
        />
      );
    },
    [
      selectedFilter,
      handleClickCheckbox,
      handleBulkSelect,
      isItemSelected,
      onApply,
      handleClearFilter,
    ]
  );

  return (
    <Wrapper ref={menuRef} $open={filterOpen} $buttonLeft={buttonLeft}>
      <InnerWrapper>
        <GlobalHireFilter
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          onApply={onApply}
        />
        {FilterList.map((filter) => (
          <MenuWrapper key={filter.title}>
            <MenuTitleComponent
              title={filter.title}
              selectedFilter={selectedFilter}
              openDropdown={openDropdown}
              handleOpenDropdown={handleOpenDropdown}
            />
            <MenuContainer
              $open={openDropdown === filter.title}
              $selected={openDropdown === filter.title}
            >
              {renderFilterContent(filter)}
            </MenuContainer>
          </MenuWrapper>
        ))}
      </InnerWrapper>
    </Wrapper>
  );
}

// Reusable Bottom Action Buttons component
function BottomActions({ onClear, onApply, selectedFilter }) {
  return (
    <BottomContainer>
      <ButtonStyled $outlined onClick={onClear}>
        Clear
      </ButtonStyled>
      <ButtonStyled onClick={() => onApply(selectedFilter)}>Apply</ButtonStyled>
    </BottomContainer>
  );
}

const GlobalHireFilter = memo(
  ({ selectedFilter, setSelectedFilter, onApply }) => {
    const [globalHireState, setGlobalHireState] = useState(false);

    function handleClickCheckbox() {
      if (globalHireState) {
        const newSelectedFilter = { ...selectedFilter, globalHire: false };
        setSelectedFilter(newSelectedFilter);
        onApply(newSelectedFilter);
      } else {
        const newSelectedFilter = { ...selectedFilter, globalHire: true };
        setSelectedFilter(newSelectedFilter);
        onApply(newSelectedFilter);
      }
    }

    useEffect(() => {
      setGlobalHireState(selectedFilter.globalHire);
    }, [selectedFilter.globalHire]);

    return (
      <MenuButton
        $globalHire
        $selected={globalHireState}
        onClick={handleClickCheckbox}
      >
        <CheckboxButton
          checked={globalHireState}
          icon={<UncheckedBox />}
          checkedIcon={
            <CheckedBox>
              <CheckboxIcon />
            </CheckedBox>
          }
          onChange={() => {}} // Add empty onChange to avoid React warning
        />
        <GlobalHireContainer>
          <MenuButtonDetail>Global Hire Only</MenuButtonDetail>
          <GlobalHireSubtitle>
            Filter for jobs that accepts international talents.
          </GlobalHireSubtitle>
        </GlobalHireContainer>
      </MenuButton>
    );
  }
);

// Memoized MenuTitleComponent
const MenuTitleComponent = memo(
  ({ title, selectedFilter, openDropdown, handleOpenDropdown }) => {
    const getFilterKeyByTitle = useCallback((title) => {
      const titleToKey = {
        Specialisation: FILTER_KEYS.SPECIALISATION,
        State: FILTER_KEYS.STATE,
        "Job Types": FILTER_KEYS.JOB_TYPES,
        Experience: FILTER_KEYS.EXPERIENCE,
        Salary: FILTER_KEYS.SALARY,
      };
      return titleToKey[title] || "";
    }, []);

    const filterLength = useMemo(() => {
      const filterKey = getFilterKeyByTitle(title);
      return selectedFilter?.[filterKey]?.length || 0;
    }, [selectedFilter, title, getFilterKeyByTitle]);

    const salaryAmount = useMemo(() => {
      if (title !== "Salary" || !selectedFilter?.salary) return null;
      return process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "my"
        ? "RM " + calibrateSalary(selectedFilter.salary)
        : calibrateSalary(selectedFilter.salary) + " SGD";
    }, [selectedFilter?.salary, title]);

    return (
      <MenuButton
        $open={openDropdown === title}
        $selected={openDropdown === title}
        onClick={() => handleOpenDropdown(title)}
      >
        <MenuButtonDetail>
          {title}
          {filterLength > 0 && <FilterCount>{filterLength}</FilterCount>}
          {salaryAmount && <FilterCount $salary>{salaryAmount}</FilterCount>}
        </MenuButtonDetail>
        <ArrowUpIcon $open={openDropdown === title} />
      </MenuButton>
    );
  }
);

// Memoized FilterSpecialisationItem
const FilterSpecialisationItem = memo(
  ({
    specList,
    selectedFilter,
    setSelectedFilter,
    handleClickCheckbox,
    isItemSelected,
    onApply,
    handleClearFilter,
  }) => {
    const [subSpecList, setSubSpecList] = useState([]);
    const fullSubSpecList = useMemo(
      () => specList?.flatMap((spec) => spec?.subSpecialisations) || [],
      [specList]
    );

    const selectText = useMemo(() => {
      if (subSpecList?.length > 0) {
        return subSpecList.some((subSpec) =>
          isItemSelected(FILTER_KEYS.SPECIALISATION, subSpec)
        )
          ? "Deselect All"
          : "Select All";
      }
      return selectedFilter?.specialisation?.length > 0
        ? "Deselect All"
        : "Select All";
    }, [subSpecList, selectedFilter?.specialisation, isItemSelected]);

    function handleBulkSelectSpec() {
      setSelectedFilter((prev) => ({
        ...prev,
        specialisation:
          prev.specialisation?.length > 0
            ? prev.specialisation.filter(
                (id) => !fullSubSpecList.some((spec) => spec.id === id)
              )
            : fullSubSpecList.map((item) => item.id),
      }));
    }

    function handleBulkSelectSubSpec() {
      setSelectedFilter((prev) => {
        const anySelected = subSpecList.some((subSpec) =>
          prev.specialisation?.some((id) => id === subSpec.id)
        );

        if (anySelected) {
          return {
            ...prev,
            specialisation: prev.specialisation?.filter(
              (id) => !subSpecList.some((subSpec) => subSpec.id === id)
            ),
          };
        }

        const currentSelected = prev.specialisation || [];
        const newIds = subSpecList
          .filter((subSpec) => !currentSelected.includes(subSpec.id))
          .map((item) => item.id);
        return {
          ...prev,
          specialisation: [...currentSelected, ...newIds],
        };
      });
    }

    return (
      <>
        {subSpecList?.length > 0 ? (
          <>
            <TopContainer>
              <SelectButton onClick={() => setSubSpecList([])}>
                <ArrowLeftIcon />
              </SelectButton>
              <SelectButton onClick={handleBulkSelectSubSpec}>
                {selectText}
              </SelectButton>
            </TopContainer>
            <MenuItemList>
              {subSpecList?.map((subSpecItem) => (
                <MenuItem
                  key={subSpecItem.id}
                  onClick={() =>
                    handleClickCheckbox(FILTER_KEYS.SPECIALISATION, subSpecItem)
                  }
                >
                  <MenuItemDetail>
                    <CheckboxButton
                      icon={<UncheckedBox />}
                      checked={isItemSelected(
                        FILTER_KEYS.SPECIALISATION,
                        subSpecItem
                      )}
                      checkedIcon={
                        <CheckedBox>
                          <CheckboxIcon />
                        </CheckedBox>
                      }
                    />
                    {subSpecItem?.name}
                  </MenuItemDetail>
                </MenuItem>
              ))}
            </MenuItemList>
          </>
        ) : (
          <>
            <TopContainer>
              <SelectButton onClick={handleBulkSelectSpec}>
                {selectText}
              </SelectButton>
            </TopContainer>
            <MenuItemList>
              {specList?.map((item) => (
                <SpecialisationTitleComponent
                  key={item.id}
                  item={item}
                  selectedFilter={selectedFilter}
                  handleSpecClick={setSubSpecList}
                />
              ))}
            </MenuItemList>
          </>
        )}
        <BottomActions
          onClear={handleClearFilter}
          onApply={onApply}
          selectedFilter={selectedFilter}
        />
      </>
    );
  }
);

// Memoized SpecialisationTitleComponent
const SpecialisationTitleComponent = memo(
  ({ item, selectedFilter, handleSpecClick }) => {
    const filterSpecialisationLength = useMemo(() => {
      if (
        !selectedFilter?.specialisation?.length ||
        selectedFilter?.specialisation?.length === 0 ||
        !item?.subSpecialisations?.length
      ) {
        return 0;
      }
      return selectedFilter.specialisation.filter((id) =>
        item.subSpecialisations.some((subSpec) => subSpec.id === id)
      ).length;
    }, [
      selectedFilter?.specialisation,
      item?.subSpecialisations,
      selectedFilter?.specialisation?.length,
    ]);

    return (
      <MenuItem onClick={() => handleSpecClick(item?.subSpecialisations)}>
        <MenuItemDetail>{item?.name}</MenuItemDetail>
        <MenuCountContainer>
          {filterSpecialisationLength > 0 && (
            <FilterSpecialisationCount>
              {filterSpecialisationLength}
            </FilterSpecialisationCount>
          )}
          <ArrowRightIcon />
        </MenuCountContainer>
      </MenuItem>
    );
  }
);

// Memoized FilterDefaultItem
const FilterDefaultItem = memo(
  ({
    list,
    filterKey,
    selectedFilter,
    handleBulkSelect,
    handleClickCheckbox,
    isItemSelected,
    onApply,
    handleClearFilter,
  }) => {
    const selectText =
      selectedFilter?.[filterKey]?.length > 0 ? "Deselect All" : "Select All";
    const itemKey = ITEM_DISPLAY_KEYS[filterKey];

    return (
      <>
        <TopContainer>
          <SelectButton onClick={() => handleBulkSelect(filterKey, list)}>
            {selectText}
          </SelectButton>
        </TopContainer>
        <MenuItemList>
          {list?.map((item) => (
            <MenuItem
              key={item.id}
              onClick={() => handleClickCheckbox(filterKey, item)}
            >
              <MenuItemDetail>
                <CheckboxButton
                  icon={<UncheckedBox />}
                  checked={isItemSelected(filterKey, item)}
                  checkedIcon={
                    <CheckedBox>
                      <CheckboxIcon />
                    </CheckedBox>
                  }
                />
                {item[itemKey]}
              </MenuItemDetail>
            </MenuItem>
          ))}
        </MenuItemList>
        <BottomActions
          onClear={handleClearFilter}
          onApply={onApply}
          selectedFilter={selectedFilter}
        />
      </>
    );
  }
);

// Memoized FilterSalaryItem
const FilterSalaryItem = memo(
  ({ selectedFilter, setSelectedFilter, onApply, handleClearFilter }) => {
    const salary = selectedFilter?.salary || 0;
    const salaryAmount = useMemo(
      () =>
        process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "my"
          ? "RM " + calibrateSalary(selectedFilter?.salary)
          : calibrateSalary(selectedFilter?.salary) + " SGD",
      [selectedFilter?.salary]
    );

    const handleSelectSalary = useCallback(
      (e) => {
        setSelectedFilter((prev) => ({
          ...prev,
          salary: e.target.value,
        }));
      },
      [setSelectedFilter]
    );

    return (
      <>
        <FilterSalaryContainer>
          minimum monthly salary
          <SliderContainer>
            <SliderStyled
              aria-labelledby="salary-range-slider"
              step={1}
              marks
              min={0}
              max={50}
              onChange={handleSelectSalary}
              value={salary}
            />
          </SliderContainer>
          {salaryAmount}
        </FilterSalaryContainer>
        <BottomActions
          onClear={handleClearFilter}
          onApply={onApply}
          selectedFilter={selectedFilter}
        />
      </>
    );
  }
);
