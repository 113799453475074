import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";
import { InView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { assetDomain } from "../../../config/config";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import { getUserWorkPersona } from "../../../redux/actions/user_action";
import {
  BodyText,
  BoxContainer,
  CardContainer,
  CloseButton,
  CloseIcon,
  HeaderText,
  ImageStyled,
  SingleSparkleIcon,
  SparklesIconStyled,
  TextContainer,
  TypeformButton,
} from "./styles";

function JobWorkPersonaCard() {
  var typeformId = process.env.NEXT_PUBLIC_WORK_PERSONA_ID;

  const isMobile = useMediaQuery("(max-width: 640px)");

  const personaImage = assetDomain + "/images/hiredly/work-persona-asset.webp";

  const [showCard, setShowCard] = useState(true);
  const [triggerImpression, setTriggerImpression] = useState(true);

  const reduxUserName = useSelector((state) => state.user?.user?.name);
  const reduxUserEmail = useSelector((state) => state.user?.user?.email);
  const reduxUserPersona = useSelector((state) => state.user?.workPersona);

  const dispatch = useDispatch();

  return (
    <CardContainer
      visibility={showCard && Object.keys(reduxUserPersona).length <= 0}
    >
      <BoxContainer
        position={"relative"}
        alignItems={"flex-start"}
        gap={isMobile ? "0.5rem" : "0.625rem"}
        sx={{ width: isMobile ? "100%" : "75%" }}
      >
        <ImageStyled src={personaImage} />

        <TextContainer>
          <HeaderText>
            Are you a Thermometer Zaman Covid or just the Tong Sampah Kosong?
          </HeaderText>
          <BodyText>
            Take this highly scientific (not really) quiz to find out what kind
            of chaotic energy you bring to work.
          </BodyText>
        </TextContainer>
      </BoxContainer>

      <BoxContainer
        justifyContent={"center"}
        alignItems={"center"}
        marginRight={isMobile ? "0" : "2.5rem"}
        sx={{ width: isMobile ? "100%" : "auto" }}
        color={"#BF8A00"}
      >
        <InView
          trackVisibility={true}
          threshold={1}
          delay={300}
          onChange={(inView, entry) => {
            // impression should only trigger when triggerimpression is true
            // if triggerimpression is false, means user's last action is opening persona popup
            if (inView === true && entry?.isVisible === true) {
              if (triggerImpression !== true) {
                return setTriggerImpression(true);
              }

              sendTrackingEvent({
                event: "CE_persona_quiz_card_impression",
              });

              // posthog.capture("persona_quiz_card_impression");
              // set triggerimpression to false as we don't want impression to trigger when closing persona popup
              setTriggerImpression(false);
            }
          }}
        >
          <TypeformButton
            className="quiz-button"
            id={typeformId || ""}
            hidden={{
              fullname: reduxUserName,
              email: reduxUserEmail,
            }}
            onReady={() => {
              sendTrackingEvent({
                event: "CE_persona_quiz_card_click",
              });

              // posthog.capture("persona_quiz_card_click");
            }}
            onSubmit={() => {
              // posthog.capture("persona_quiz_complete", {
              //   persona: reduxUserPersona?.attributes?.personaId ?? null,
              //   first_time: reduxUserPersona?.attributes?.personaId
              //     ? false
              //     : true,
              // });

              sendTrackingEvent({
                event: "CE_persona_quiz_complete",
                persona: reduxUserPersona.attributes?.personaId ?? null,
                first_time: reduxUserPersona.attributes?.personaId
                  ? false
                  : true,
              });
            }}
            onClose={() => {
              reduxUserEmail ? dispatch(getUserWorkPersona()) : null;
            }}
          >
            Take the Test <SparklesIconStyled />
          </TypeformButton>
        </InView>
        <SingleSparkleIcon
          sx={
            isMobile
              ? {
                  bottom: "22px",
                  left: "22px",
                  height: "20px",
                  width: "20px",
                }
              : {
                  top: "27px",
                  right: "224px",
                  height: "20px",
                  width: "20px",
                }
          }
        />
        <SingleSparkleIcon
          sx={
            isMobile
              ? {
                  bottom: "40px",
                  left: "40px",
                  height: "16px",
                  width: "16px",
                }
              : {
                  top: "13px",
                  right: "210px",
                  height: "16px",
                  width: "16px",
                }
          }
        />
        <SingleSparkleIcon
          sx={{
            bottom: isMobile ? "4px" : "12px",
            right: isMobile ? "28px" : "28px",
            height: "20px",
            width: "20px",
          }}
        />
      </BoxContainer>

      <CloseButton
        onClick={() => {
          sendTrackingEvent({
            event: "CE_persona_quiz_card_close",
          });
          // posthog.capture("persona_quiz_card_close");

          setShowCard(false);
        }}
      >
        <CloseIcon />
      </CloseButton>
    </CardContainer>
  );
}

export default JobWorkPersonaCard;
