import useMediaQuery from "@mui/material/useMediaQuery";
import { useIsClient } from "@uidotdev/usehooks";
import Cookies from "js-cookie";
import { throttle } from "lodash";
import { useRouter } from "next/router";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formJobQueryParam } from "../../../functions/job";
import {
  calibrateSalary,
  getGaSessionID,
} from "../../../helpers/data_management";
import {
  convertIdsToUrl,
  decodePage,
  encodePage,
} from "../../../helpers/filter_management";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import { updatePostApplyDialog } from "../../../redux/actions/dialog_action";
import {
  clearJobList,
  getExperienceLevels,
  getJobs,
  getJobsParams,
  getJobTypes,
  getSpecialisation,
  getStateRegions,
  postGaSessionId,
  updateHoverJob,
  updateJobListFilter,
  updateJobSearchKeyword,
} from "../../../redux/actions/job_action";
import { checkAuthentication } from "../../../redux/actions/user_action";
import { store } from "../../../redux/stores/store";
import * as jobTypes from "../../../redux/types/job_type";
import SharedPagination from "../../shared/SharedPagination/SharedPagination";
import AccordionJobCard from "../AccordionJobCard/AccordionJobCard";
import AiJobSummary from "../AiJobSummary/AiJobSummary";
import JobAlertToggle from "../JobAlertToggle/JobAlertToggle";
import JobListNoResult from "../JobListNoResult/JobListNoResult";
import JobMobileAppAdCard from "../JobMobileAppAdCard/JobMobileAppAdCard";
import JobWorkPersonaCard from "../JobWorkPersonaCard/JobWorkPersonaCard";
import {
  AccordionJobListSkeleton,
  AccordionMobileJobListSkeleton,
} from "../Skeleton/Skeleton";
import { JobListContainer, Wrapper } from "./styles";

export default function AccordionJobList(props) {
  const {
    serverJobs,
    ssrFilterParams,
    ssrPageParams,
    onApplyJobClick,
    onSimilarJobsClick,
  } = props;

  const dispatch = useDispatch();
  const router = useRouter();

  const isClient = useIsClient();
  const isMobile = useMediaQuery("(max-width: 640px)");

  const isLoggedIn = checkAuthentication();

  const firstTimeMountLoadingJobs = useRef(true);

  const [jobsLoading, setJobsLoading] = useState(true);
  const [renderedJobList, setRenderedJobList] = useState(serverJobs);

  // User Related Items
  const user = useSelector((state) => state.user?.user);

  const workPersonaStatus = user?.finishedWorkPersona;

  // Filter Related Items
  const specialisationList = useSelector((state) => state.jobs?.specialisation);
  const stateList = useSelector((state) => state.jobs?.stateRegions);
  const jobTypeList = useSelector((state) => state.jobs?.jobTypes);
  const experienceLevelList = useSelector(
    (state) => state.jobs?.experienceLevels
  );

  // Jobs Related Items
  const fetchingJobs = useSelector((state) => state.jobs.fetchingJobList);
  const jobList = useSelector((state) => state.jobs?.jobs);

  const loadingJobs = fetchingJobs || jobsLoading;

  const searchType = useSelector((state) => state.jobs.searchType);
  const jobListFilter = useSelector((state) => state?.jobs?.jobListFilter);

  // Pagination Related Items
  const totalPages = useSelector((state) => state.jobs.totalJobsPages);
  const jobPageInfo = useSelector((state) => state.jobs.jobPageInfoV1);
  const currentPage = !jobPageInfo?.hasNextPage
    ? decodePage(jobPageInfo?.endCursor, 30) + 1
    : decodePage(jobPageInfo?.endCursor, 30);

  // DYNAMIC CONST LOGIC
  const searchTerm = jobListFilter?.keyword;
  const showWorkPersonaCard = isLoggedIn && workPersonaStatus;

  // FUNCTION LOGIC
  function onHoverEnter(job) {
    dispatch(updateHoverJob(job));
  }

  const throttledOnHoverEnter = throttle(onHoverEnter, 175);

  // Clears hover state when leaving job card
  function onHoverLeave() {
    dispatch(updateHoverJob({}));
  }

  // handle trigger ga session id
  function handleTriggerGaSessionId(job) {
    const gaSessionId = getGaSessionID();

    if (gaSessionId) {
      dispatch(
        postGaSessionId({
          jobId: job?.id,
          sessionId: gaSessionId,
        })
      );
    }
  }

  /*
   * Handles job card click events including:
   * - Analytics tracking
   * - URL generation and navigation
   * - Cross-border job handling
   */

  function onJobCardClick(e, job) {
    e.stopPropagation();
    e.preventDefault();

    handleTriggerGaSessionId(job);

    sendTrackingEvent({
      event: "CE_job-click",
      "job-id": job?.id,
      origin: "job_list",
      "job-discovery": "search",
      search_term: searchTerm ?? "",
    });

    Cookies.set("click-source", "job_listing_card");

    const url =
      job?.crossedBorder && job?.scraped
        ? job?.crossBorderData?.source_country === "SG" &&
          process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "my"
          ? job?.crossBorderData?.source_country_url +
            `?source=my&origin=job_list`
          : job?.crossBorderData?.source_country_url
        : `/jobs/${job?.slug}?origin=job_list`;

    window.open(url);
  }

  /*
   * Handles job application flow including:
   * - User authentication check
   * - Profile completeness validation
   * - Resume verification
   * - Application submission
   * - Analytics tracking
   */

  /**
   * Fetches job listings with optional refresh functionality
   *
   * This function handles:
   * 1. Job data fetching with pagination
   * 2. Loading state management
   * 3. Analytics tracking
   * 4. GraphQL request cancellation
   * 5. Redux state updates
   */
  function fetchJobs(refresh = false, jobParams, currentPageParams = null) {
    // Prevent concurrent fetches unless explicitly refreshing
    if (store.getState().jobs.isLoadingJobs && !refresh) return;

    // Cancel any in-flight GraphQL requests to prevent race conditions
    if (store.getState().axios.cancelTokens[jobTypes.FETCHING_JOBS_KEY]) {
      store
        .getState()
        .axios.cancelTokens[jobTypes.FETCHING_JOBS_KEY].cancel(
          "job list search"
        );
    }

    scrollTo(0, 0);

    // Set loading state in Redux
    setJobsLoading(true);
    dispatch(clearJobList());
    if (isClient) {
      setRenderedJobList([]);
    }

    // Generate unique key to track current request
    const currentLoadingJobsKey = new Date().getTime();
    store.getState().jobs.currentLoadingJobsKey = currentLoadingJobsKey;

    // Build query parameters from current filter state
    const params = formJobQueryParam(jobParams, refresh);

    // Configure pagination settings
    const pagination = {
      first: 30, // Number of items per page
      last: null,
      startCursor: currentPageParams, // Current page cursor
      endCursor: null, // Next page cursor
    };

    // Handle analytics tracking (skipped on first mount)
    if (firstTimeMountLoadingJobs.current === false) {
      // Process state/region data for analytics
      const preProcessStateRegion =
        params?.stateRegions && Array.isArray(params?.stateRegions)
          ? params.stateRegions.join(", ").length !== 0
            ? params.stateRegions
            : null
          : null;

      // Process experience levels
      const regex = /\d+(\.\d+)?/g;
      const selectedExp =
        experienceLevelList
          .filter((exp) => params?.experienceIds?.includes(parseInt(exp.id)))
          .map((match) =>
            match.title.toLowerCase().replace(/intern|fresh graduate/g, "0")
          ) ?? [];

      const cleanSelectExp = selectedExp.join().match(regex)?.sort() ?? [];

      // Check if any filters are applied
      const hasFilters = !!(
        params?.trackIds?.length > 0 ||
        params?.stateRegions?.length > 0 ||
        params?.jobTypeIds?.length > 0 ||
        params?.experienceIds?.length > 0 ||
        params?.expectedSalary > 0 ||
        params?.globalHire
      );

      // Send analytics event with detailed search parameters
      sendTrackingEvent({
        event: "CE_job_search_jlp",
        "search-term":
          params.keyword && params.keyword !== "*" ? params.keyword : "",
        has_filters: hasFilters ? "true" : "false",
        input_type: searchType || "unknown",
        specialisation: params.trackIds?.join(",") || "",
        states: preProcessStateRegion?.join(",") || "",
        "job-type": params.jobTypeIds?.join(",") || "",
        experience: cleanSelectExp.join("-") || "0",
        salary:
          params.expectedSalary &&
          Number.isInteger(params.expectedSalary) &&
          params.expectedSalary > 0
            ? calibrateSalary(params.expectedSalary).toString()
            : "0",
        "ct-specialization":
          specialisationList
            .filter((track) => params?.trackIds?.includes(parseInt(track.id)))
            .map((match) => match.slug)
            .join(",") || "",
        "ct-job-types":
          jobTypeList
            .filter((type) => params?.jobTypeIds?.includes(parseInt(type.id)))
            .map((match) => match.title)
            .join(",") || "",
        "ct-min-exp": parseInt(cleanSelectExp[0] ?? 0) || 0,
        "ct-max-exp":
          parseInt(cleanSelectExp[cleanSelectExp.length - 1] ?? 0) || 0,
      });
    } else {
      // Mark first mount as complete
      firstTimeMountLoadingJobs.current = false;
    }

    // Store current search parameters in Redux
    dispatch(getJobsParams(params));

    // Return promise chain with proper error handling
    return dispatch(getJobs(params, pagination))
      .then((response) => {
        if (response?.type === "FETCH_JOBS_SUCCEED") {
        }
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  function jobListPreviousPage() {
    jobListPageChange(currentPage - 1);
  }

  function jobListNextPage() {
    jobListPageChange(currentPage + 1);
  }

  function jobListPageChange(page) {
    const { path, query } = convertIdsToUrl(
      jobListFilter,
      specialisationList,
      stateList
    );

    const newQuery = query + (query ? "&page=" : "page=") + page;

    router.push({ pathname: path, query: newQuery }, undefined, {
      shallow: true,
    });

    fetchJobs(false, jobListFilter, encodePage(page, 30));
  }

  useEffect(() => {
    if (isClient && jobList?.length > 0 && !fetchingJobs) {
      setRenderedJobList(jobList);
    }

    if (!fetchingJobs) {
      setJobsLoading(false);
    }
  }, [isClient, jobList, fetchingJobs]);

  useEffect(() => {
    if (isClient) {
      fetchJobs(true, jobListFilter, ssrPageParams);
    } else {
      fetchJobs(true, ssrFilterParams, ssrPageParams);
    }
  }, [jobListFilter]);

  useEffect(() => {
    const scrapedJobId = localStorage.getItem("scraped_job_id");

    if (scrapedJobId !== "undefined" && scrapedJobId !== null) {
      dispatch(
        updatePostApplyDialog({
          status: true,
          scraped: true,
          jobGlobalId: localStorage.getItem("scraped_job_id"),
        })
      );
    }

    dispatch(getSpecialisation());
    dispatch(getStateRegions());
    dispatch(getExperienceLevels());
    dispatch(getJobTypes());

    // Add error handling for ssrFilterParams
    if (ssrFilterParams?.keyword !== undefined) {
      dispatch(updateJobSearchKeyword({ keyword: ssrFilterParams?.keyword }));
    }
    dispatch(updateJobListFilter(ssrFilterParams || {}));
  }, [dispatch]);

  return (
    <Wrapper>
      <JobListContainer>
        {loadingJobs ? (
          Array.from({ length: 10 }).map((_, index) => {
            return isMobile ? (
              <AccordionMobileJobListSkeleton key={index} />
            ) : (
              <AccordionJobListSkeleton key={index} />
            );
          })
        ) : (
          <>
            {renderedJobList?.length > 0 ? (
              <>
                <JobAlertToggle />
                {renderedJobList?.map((job, index) => (
                  <Fragment key={index}>
                    {index === 4 && isClient && (
                      <>
                        {!isLoggedIn || !showWorkPersonaCard ? (
                          <JobWorkPersonaCard />
                        ) : (
                          <JobMobileAppAdCard />
                        )}
                      </>
                    )}
                    {index === 10 && isClient ? (
                      <JobAlertToggle
                        jobAlertBannerId={`job-alert-banner-${index}`}
                      />
                    ) : index === 20 && isClient ? (
                      <JobAlertToggle
                        jobAlertBannerId={`job-alert-banner-${index}`}
                      />
                    ) : null}

                    <AccordionJobCard
                      key={job.id}
                      job={job}
                      onHoverEnter={throttledOnHoverEnter}
                      onJobCardClick={onJobCardClick}
                      onApplyJobClick={onApplyJobClick}
                      onSimilarJobsClick={onSimilarJobsClick}
                    />
                  </Fragment>
                ))}
              </>
            ) : (
              <JobListNoResult />
            )}
          </>
        )}

        <SharedPagination
          currentPage={currentPage}
          totalPages={totalPages}
          isLoading={loadingJobs}
          onPageChange={jobListPageChange}
          onNextPage={jobListNextPage}
          onPreviousPage={jobListPreviousPage}
        />
      </JobListContainer>
      <AiJobSummary onHoverLeave={onHoverLeave} />
    </Wrapper>
  );
}
