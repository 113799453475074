import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import {
  updateJobAlertCreateDialog,
  updateJobAlertCreateDialogWithKeyword,
  updateVisitorJobAlertDialogDetails,
} from "../../../redux/actions/dialog_action";
import { updateJobAlertEmail } from "../../../redux/actions/job_action";
import { checkAuthentication } from "../../../redux/actions/user_action";
import {
  AlertCopyText,
  ButtonContainer,
  CheckCircleIconStyled,
  CloseIconStyled,
  ContentContainer,
  IconButtonStyled,
  InputBaseAdornmentStyled,
  InputBaseStyled,
  JobAlertContainer,
  LabelContainer,
  NotificationsIconStyled,
  PaperPlaneIconStyled,
  SetAlertButtonStyled,
} from "./styles";

function JobAlertToggle(props) {
  const { jobAlertBannerId } = props;

  const isMobile = useMediaQuery("(max-width:640px)");

  const dispatch = useDispatch();
  const router = useRouter();

  const isLoggedIn = checkAuthentication();

  const jobAlerts = useSelector((state) => state.jobs.jobAlerts);
  const searchKeyword =
    useSelector((state) => state.jobs.jobListFilter.keyword) ?? "";
  const showSignInModal = useSelector((state) => state.navbar.showSignInModal);
  const showJobAlertVisitorEmailDialog = useSelector(
    (state) => state.dialog.showJobAlertVisitorEmailDialog
  );

  const [active, setActive] = useState(false);
  const [showJobAlertBanner, setShowJobAlertBanner] = useState(true);

  const url = window.location.search;
  const urlParams = new URLSearchParams(url);
  const cAlert = urlParams.get("title");

  const toggleText = () => {
    if (active) {
      return "Job Alert Created";
    } else {
      if (isMobile) {
        return "";
      } else {
        return "Get Notified for Similar Jobs";
      }
    }
  };

  function checkJobAlertExists(keyword) {
    if (!keyword) return;

    return jobAlerts.some((alert) => {
      const alertTitle = alert.title?.toString().toLowerCase().trim();
      const keywordLower = keyword.toString().toLowerCase().trim();
      return alertTitle === keywordLower;
    });
  }

  function handleCloseBanner() {
    sendTrackingEvent({
      event: "CE_close-job-alert-banner",
    });
    setShowJobAlertBanner(false);
  }

  function handleEmailInputChange(event) {
    dispatch(
      updateVisitorJobAlertDialogDetails({
        email: event.target.value,
      })
    );
    dispatch(updateJobAlertEmail(event.currentTarget.value));
  }

  function handleSetVisitorAlert() {
    // dispatch(
    //   updateVisitorJobAlertDialogDetails({
    //     keyword: searchKeyword,
    //   })
    // );
    // dispatch(updateVisitorJobAlertDialogOrigin("job-alert-banner"));
    dispatch(
      updateJobAlertCreateDialogWithKeyword({
        keyword: searchKeyword ?? "",
        origin: "job-alert-banner",
      })
    );
    dispatch(updateJobAlertCreateDialog(true));
    // dispatch(updateShowJobAlertDialog(true));
  }

  function handleSetAlert() {
    return active
      ? router.push("/profile#manage-job-alerts")
      : dispatch(
          updateJobAlertCreateDialogWithKeyword({
            keyword: searchKeyword,
            origin: "job-alert-banner",
          })
        ).then(() => dispatch(updateJobAlertCreateDialog(true)));
  }

  useEffect(() => {
    if (cAlert && cAlert == searchKeyword) {
      setActive(true);
    }
  }, [cAlert, searchKeyword]);

  useEffect(() => {
    // Only for visitor
    if (!isLoggedIn && searchKeyword) {
      if (showJobAlertVisitorEmailDialog || showSignInModal) {
        setActive(true);
      } else {
        setActive(false);
      }
    }
  }, [showJobAlertVisitorEmailDialog, showSignInModal]);

  useEffect(() => {
    if (checkJobAlertExists(searchKeyword)) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [jobAlerts]);

  return (
    <JobAlertContainer
      id={jobAlertBannerId}
      open={showJobAlertBanner}
      $active={active}
    >
      <ContentContainer>
        <LabelContainer $active={active}>
          {!active ? <NotificationsIconStyled /> : <CheckCircleIconStyled />}
          {isLoggedIn || !isMobile ? (
            <AlertCopyText>{toggleText()}</AlertCopyText>
          ) : null}
        </LabelContainer>
        <ButtonContainer>
          {isLoggedIn ? (
            <SetAlertButtonStyled variant="contained" onClick={handleSetAlert}>
              {active ? "Manage Job Alerts" : "Set Alert"}
            </SetAlertButtonStyled>
          ) : (
            <InputBaseStyled
              $active
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSetVisitorAlert();
                }
              }}
              placeholder={
                isMobile ? "Enter email for job updates" : "Your email address"
              }
              onChange={handleEmailInputChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={handleSetAlert}>
                    <InputBaseAdornmentStyled>
                      {isMobile ? <PaperPlaneIconStyled /> : "Set Alert"}
                    </InputBaseAdornmentStyled>
                  </IconButton>
                </InputAdornment>
              }
            />
          )}

          <IconButtonStyled
            aria-label="Close"
            size="small"
            onClick={handleCloseBanner}
          >
            <CloseIconStyled />
          </IconButtonStyled>
        </ButtonContainer>
      </ContentContainer>
    </JobAlertContainer>
  );
}

export default JobAlertToggle;
