import { useIsClient } from "@uidotdev/usehooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearJobList,
  getAshleyRecommendedJobs,
} from "../../../redux/actions/job_action";
import { checkAuthentication } from "../../../redux/actions/user_action";
import { store } from "../../../redux/stores/store";
import ForYouJobList from "../../for-you/ForYouJobList/ForYouJobList";
import ForYouResume from "../../for-you/ForYouResume/ForYouResume";
import VisitorPlaceholder from "../../for-you/VisitorPlaceholder/VisitorPlaceholder";

export default function AccordionForYou(props) {
  const {
    jobs,
    jobListTab,
    jobListRef,
    handleTabChange,
    handleRefreshJobs,
    handleUndoRefresh,
    selectedJobs,
    setSelectedJobs,
    filteredJobs,
    setViewedCards,
    setDialogOpen,
    currentPage,
    setCurrentPage,
    refreshLoading,
    setViewedRecommendedJobsCards,
  } = props;

  const isClient = useIsClient();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user?.user);
  const jobReload = useSelector((state) => state.jobs.jobReload);
  const isLoadingJobs = useSelector((state) => state.jobs.isLoadingJobs);
  const fetchingJobs = useSelector((state) => state.jobs.fetchingJobList);

  const loadingJobs = fetchingJobs || isLoadingJobs;

  const isLoggedIn = checkAuthentication();

  const showResumePlaceholder =
    (isLoggedIn && (user?.resume === "" || user?.state !== "approved")) ||
    (jobs?.length === 0 && !loadingJobs);

  const showJobListPlaceholder =
    user?.state === "approved" && (jobs?.length > 0 || loadingJobs);

  function fetchRecommendedJobs() {
    // Prevent concurrent fetches unless explicitly refreshing
    if (store.getState().jobs.isLoadingJobs) return;

    const jobKey = new Date().getTime();

    const params = {
      currentLoadingJobsKey: jobKey,
      first: 50,
      last: null,
      startCursor: null,
      endCursor: null,
    };

    dispatch(clearJobList()).then(() => {
      dispatch(getAshleyRecommendedJobs(params));
    });
  }

  useEffect(() => {
    if (jobListTab === 1) {
      return;
    }

    setViewedRecommendedJobsCards(new Set());
    fetchRecommendedJobs();
  }, [jobListTab, jobReload]);

  return isClient ? (
    <>
      {!isLoggedIn && <VisitorPlaceholder />}
      {showResumePlaceholder && (
        <ForYouResume handleTabChange={handleTabChange} />
      )}
      {showJobListPlaceholder && (
        <ForYouJobList
          handleRefreshJobs={handleRefreshJobs}
          handleUndoRefresh={handleUndoRefresh}
          selectedJobs={selectedJobs}
          setSelectedJobs={setSelectedJobs}
          filteredJobs={filteredJobs}
          jobs={jobs}
          jobListRef={jobListRef}
          setViewedCards={setViewedCards}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          refreshLoading={refreshLoading}
          setDialogOpen={setDialogOpen}
        />
      )}
    </>
  ) : null;
}
