import useMediaQuery from "@mui/material/useMediaQuery";
import { useIsClient } from "@uidotdev/usehooks";
import "react-toastify/dist/ReactToastify.css";
import JobSearchOptions from "../JobSearchOptions/JobSearchOptions";
import {
  FypIcon,
  SearchIcon,
  TabNav,
  TabNavContainer,
  TabNavWrapper,
} from "./styles";

export default function JobListNavigation(props) {
  const { selectedTab, handleTabNavClick } = props;
  const isClient = useIsClient();
  const isDesktop = useMediaQuery("(min-width: 1280px)");

  return isClient ? (
    <TabNavWrapper>
      <TabNavContainer>
        <TabNav
          onClick={() => handleTabNavClick("for-you")}
          $selected={selectedTab === "for-you"}
        >
          <FypIcon />
          <span>Jobs for you</span>
        </TabNav>
        <TabNav
          onClick={() => handleTabNavClick("job-list")}
          $selected={selectedTab === "job-list"}
        >
          {selectedTab === "job-list" && isDesktop ? (
            <JobSearchOptions />
          ) : (
            <>
              <SearchIcon />
              <span>Search</span>
            </>
          )}
        </TabNav>
      </TabNavContainer>
    </TabNavWrapper>
  ) : (
    <TabNavWrapper></TabNavWrapper>
  );
}
