import MagnifyingGlassIcon from "@heroicons/react/20/solid/MagnifyingGlassIcon";
import SparklesIcon from "@heroicons/react/20/solid/SparklesIcon";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";

export const TabNavWrapper = styling("div")((props) => ({
  position: "sticky",
  top: "64px",
  zIndex: "10",

  backgroundColor: Color.white,
  borderBottom: "2px solid " + Color.black,

  height: "68px",

  "@media (max-width: 1279px)": {
    top: "52px",
    height: "52px",
  },

  "@media (max-width: 839px)": {
    top: "52px",
  },

  "@media (max-width: 639px)": {
    top: "60px",
  },
}));

export const TabNavContainer = styling("div")({
  display: "flex",

  maxWidth: "1440px",
  margin: "0 auto",
  padding: "0.75rem 5rem 0",

  "@media (max-width: 1279px)": {
    padding: "0.5rem 1.5rem 0",
  },

  "@media (max-width: 640px)": {
    justifyContent: "center",
    padding: "0.5rem 1rem 0",
  },
});

export const TabNav = styling("button")((props) => ({
  backgroundColor: Color.white,
  borderRadius: "0.5rem 0.5rem 0 0",
  border: "none",
  borderBottom: "2px solid " + Color.black,

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.75rem",

  fontFamily: "Inter",
  fontSize: "1rem",
  lineHeight: "24px",
  letterSpacing: "0.1px",

  height: "56px",
  padding: "0 1rem 0",

  transition: "all 0.05s ease-in-out",

  ":hover": {
    cursor: props.$selected ? "default" : "pointer",
  },

  ...(props.$selected && {
    color: Color.hiredlyPurple,
    backgroundColor: Color.backgroundGrey,
    border: "2px solid " + Color.black,
    borderBottom: "none",

    fontWeight: "700",

    "& > span": {
      textDecorationLine: "underline",
      textUnderlinePosition: "from-font",
    },

    padding: "0 1rem 0.25rem",
  }),

  "@media (max-width: 1279px)": {
    fontSize: "0.875rem",
    height: "44px",
    width: "160px",
  },
}));

export const FypIcon = styling(SparklesIcon)({
  height: "20px",
  width: "20px",

  "@media (max-width: 1279px)": {
    height: "16px",
    width: "16px",
  },
});

export const SearchIcon = styling(MagnifyingGlassIcon)({
  height: "20px",
  width: "20px",

  "@media (max-width: 1279px)": {
    height: "16px",
    width: "16px",
  },
});
