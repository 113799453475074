import MagnifyingGlassIcon from "@heroicons/react/16/solid/MagnifyingGlassIcon";
import XMarkIcon from "@heroicons/react/16/solid/XMarkIcon";
import Badge from "@mui/material/Badge";
import ButtonBase from "@mui/material/ButtonBase";
import InputBase from "@mui/material/InputBase";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";
import FilterSVG from "../../../assets/svg/filter-icon.svg";
import InternshipSVG from "../../../assets/svg/internship-icon.svg";

export const Wrapper = styling("div")({
  position: "relative",
});

export const SearchOptionsWrapper = styling("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "0.375rem",

  height: "100%",
  width: "100%",
  padding: "0.625rem 0 0.325rem",

  "@media (max-width: 1279.9px)": {
    padding: "1rem 1.5rem 0",
  },

  "@media (max-width: 640px)": {
    padding: "1rem 1rem 0",
  },
});

export const SearchBarWrapper = styling("div")((props) => ({
  backgroundColor: Color.white,
  color: Color.darkGrey,
  border: `1px solid ${Color.secondaryGrey}`,
  borderRadius: "8px",

  display: "flex",
  alignItems: "center",
  gap: "0.375rem",

  height: "36px",
  width: "480px",
  padding: "0.375rem 0.5rem 0.375rem 1rem",

  transition: "all 0.05s ease-in-out",

  ...(props.$active && {
    border: `1px solid ${Color.hiredlyPurple}`,
    boxShadow: `0px 0px 0px 1px ${Color.hiredlyPurple}`,
  }),

  ...(props.disabled && {
    pointerEvents: "none",
    opacity: "0.5",
  }),
}));

export const SearchDropdownContainer = styling("div")((props) => ({
  position: "absolute",
  top: "55px",
  zIndex: 20,

  backgroundColor: Color.white,
  borderRadius: "0.625rem",
  boxShadow: "0px 4px 19px rgba(0, 0, 0, 0.25)",

  width: "100%",
  padding: "0.5rem",
  maxWidth: "370px",

  "@media (max-width: 1279px)": {
    top: "184px",
  },
}));

export const SearchSuggestionContainer = styling("div")({
  color: Color.textHighEmphasis,
  borderRadius: "0.5rem",

  fontSize: "0.875rem",
  fontWeight: "400",
  textAlign: "left",

  marginBottom: "5px",
  padding: "0.25rem 0.5rem",
  cursor: "pointer",

  ":hover": {
    backgroundColor: Color.lightGrey,
    cursor: "pointer",
  },
});

export const SearchInput = styling(InputBase)({
  fontSize: "0.875rem",

  width: "100%",
  padding: "0",
});

export const ClearButton = styling(ButtonBase)({
  height: "24px",
  maxWidth: "24px",
  width: "100%",
});

export const SearchButton = styling(ButtonBase)({
  backgroundColor: Color.hiredlyPurple,
  color: Color.white,
  borderRadius: "4px",

  height: "24px",
  maxWidth: "24px",
  width: "100%",
});

export const FilterButton = styling(ButtonBase)((props) => ({
  backgroundColor: Color.white,
  color: Color.darkGrey,
  border: `1px solid ${Color.secondaryGrey}`,
  borderRadius: "8px",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "0.5rem",

  fontFamily: "Inter",
  fontSize: "0.875rem",
  lineHeight: "24px",
  letterSpacing: "0.15px",
  textDecoration: "none !important",
  textDecorationLine: "none !important",
  textUnderlinePosition: "unset !important",

  height: "36px",
  width: "fit-content",
  padding: "0 0.625rem",

  transition: "all 0.15s ease-in-out",

  "&:hover": {
    color: Color.textMediumEmphasis,
    border: `1px solid ${Color.textMediumEmphasis}`,
    boxShadow: `0px 0px 0px 0.5px ${Color.textMediumEmphasis}`,
  },

  ...(props.$active && {
    backgroundColor: Color.lightHiredlyPurple,
    color: Color.hiredlyPurple,
    border: `1px solid ${Color.hiredlyPurple}`,
    boxShadow: `0px 0px 0px 1px ${Color.hiredlyPurple}`,

    "&:hover": {
      backgroundColor: "rgba(115, 95, 180, 1)",
      color: Color.white,
      border: `1px solid ${Color.darkPurple}`,
      boxShadow: `0px 0px 0px 1px ${Color.darkPurple}`,
    },
  }),

  "&:disabled": {
    pointerEvents: "none",
    opacity: "0.5",
  },

  "@media (max-width: 640px)": {
    width: "100%",
  },
}));

export const BadgeStyled = styling(Badge)({
  "& .MuiBadge-badge": {
    height: "1.5rem",
    width: "1.5rem",
    borderRadius: "0.25rem",

    fontSize: "0.75rem",
    fontWeight: "400",
  },

  "@media (max-width: 640px)": {
    flex: "1",
    minWidth: "fit-content",
    width: "100%",
  },
});

// Icon Components //
export const SearchIcon = styling(MagnifyingGlassIcon)({
  height: "16px",
  width: "16px",
});

export const ClearIcon = styling(XMarkIcon)({
  height: "16px",
  width: "16px",
});

export const FilterIcon = styling(FilterSVG)({
  height: "20px",
  width: "20px",
  padding: "0.25rem",
});

export const InternshipIcon = styling(InternshipSVG)({
  height: "20px",
  width: "20px",
});
